import {httpDelete, httpGet, httpPost, httpPut} from '@/plugins/request'

/**
 * 获取notes
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetNotes(query = []) {
    return httpGet('/share/note', query)
}

/**
 *  获取笔记code
 *
 * @param code
 * @returns {Promise<*>}
 */
export function apiGetNoteCode(code) {
    return httpGet(`/share/note/${code}/info`)
}

/**
 * 保存笔记
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiPostNote(data = []) {
    return httpPost('/share/note', data)
}

/**
 * 更新笔记
 * @param id
 * @param data
 * @returns {Promise<*>}
 */
export function apiPutNote(id, data) {
    return httpPut(`/share/note/${id}`, data)
}

/**
 * 删除笔记
 *
 * @param id
 * @param query
 * @returns {Promise<*>}
 */
export function apiDeleteNote(id, query = []) {
    return httpDelete(`/share/note/${id}`, query)
}
