<template>
    <div>
      <!--------  瀑布流 start ----------->
      <el-card v-loading="loading" element-loading-text="加载中...">
          <template #header>
              <div class="card-header" style="text-align: center;">
                  <span v-text="group.project_name + ' ' + group.name"></span>
                  <el-button
                      v-if="hasRoles('create')"
                      style="float:right"
                      type="success"
                      @click="clickCreate()">
                      写笔记
                  </el-button>
              </div>
          </template>

              <div v-show="!tableData.length">
                  还没有笔记哦！
              </div>

              <Waterfall :list="tableData" :gutter="5" :breakpoints="breakpoints">
                  <template #item="{ item }" :rowKey="item.id">
                      <el-card>
                          <div style="font-size:12px;white-space: pre-wrap;">
                              <div>
                                  <b>
                                      <span v-text="item.id + '. '"></span>
                                      <span v-text="item.title"></span>
                                  </b>
                              </div>
                              <div><span v-text="item.content" /></div>
                              <div>
                                  <el-button
                                      v-if="hasRoles('edit')"
                                      @click="clickEdit(item)"
                                      size="small"
                                      type="primary">
                                      编辑
                                  </el-button>
                                  <el-button
                                      v-if="hasRoles('delete')"
                                      @click="clickDestroy(item)"
                                      size="small"
                                      type="danger">
                                      删除
                                  </el-button>
                              </div>
                          </div>
                      </el-card>
                  </template>
              </Waterfall>

          <!--------  瀑布流 end ----------->


              <!----- 分页 start------->
              <el-pagination
                  background
                  hide-on-single-page
                  prev-text="上一页"
                  next-text="下一页"
                  layout="prev, pager, next, total"
                  :total="pageData.total"
                  v-model:current-page="searchData.page"
                  @update:current-page="getNotes()"
                  class="mt-4"
              />
              <!----- 分页 end------->
      </el-card>

    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="写笔记" fullscreen append-to-body>
        <el-form
            ref="share-note"
            :rules="formData.rules"
            :model="formData.data"
        >
            <el-form-item label="标题" prop="title">
                <el-input
                    v-model="formData.data.title"
                    show-word-limit
                    maxlength="20"
                    placeholder="笔记标题"/>
            </el-form-item>
            <el-form-item label="内容" prop="content">
                <el-input
                    type="textarea"
                    rows="20"
                    show-word-limit
                    minlength="5"
                    maxlength="1500"
                    v-model="formData.data.content"
                    placeholder="笔记内容" />
            </el-form-item>
        </el-form>

        <template #footer>
              <span class="dialog-footer">
                <el-button @click="closeDialog">取消</el-button>
                <el-button type="primary" @click="onSubmit">提交</el-button>
              </span>
        </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
    </div>
</template>

<script>
  import { Waterfall } from 'vue-waterfall-plugin-next'
  import 'vue-waterfall-plugin-next/dist/style.css'
  import {apiGetNoteCode, apiGetNotes, apiPostNote, apiPutNote, apiDeleteNote} from '@/api/shareNoteApi'

  const form = {
      id: 0,
      code: '',
      title: '',
      content: ''
  }
  export default {
      name: 'share.note.index',
      components: {
          Waterfall
      },
      created() {
          this.searchData.code = this.$route.params.code
          this.init()
      },
      data () {
        return {
            group: {
                name: '',
                code: '',
                roles: [],
                id: '',
                project_id: '',
                project_name: '',
            },
            loading: true,
            breakpoints: {
                //当屏幕宽度小于等于1200
                2000: {rowPerView: 6},
                1400: {rowPerView: 4},
                1200: {rowPerView: 3},
                800: {rowPerView: 2},
                500: {rowPerView: 1}
            },
            tableData: [],
            searchData: {
                page: 1,
                code: ''
            },
            pageData: {
                total: 0,
            },
            formData: {
                show: false,
                data: form
            }
        }
      },
      methods: {
          init () {
              apiGetNoteCode(this.searchData.code).then(data => {
                  this.group = data
                  this.loading = false

                  console.log(this.group)

                  this.getNotes()
              })
          },
          getNotes () {
              apiGetNotes({code: this.searchData.code}).then((data) => {
                  this.tableData = data.data
                  this.pageData = data.meta
              })
          },
          // 是否有权限
          hasRoles(role){
              return this.group.roles.indexOf(role) > -1
          },
          onSubmit () {
              this.$refs['share-note'].validate(valid => {
                  if (valid) {

                      this.formData.data.code = this.searchData.code

                      if (this.formData.data.id) {
                          apiPutNote(this.formData.data.id, this.formData.data).then(() => {
                              this.$message.success('编辑成功')
                              this.getNotes()
                              this.closeDialog()
                          })
                      } else {
                          apiPostNote(this.formData.data).then(() => {
                              this.$message.success('创建成功')
                              this.getNotes()
                              this.closeDialog()
                          })
                      }
                  }
              })
          },
          clickCreate() {
              this.formData.data = form
              this.showDialog()
          },
          clickEdit (row) {
              this.showDialog()
              this.formData.data = row
          },
          // 点击删除
          clickDestroy(row) {
              this.$messageBox.confirm('确定删除吗').then(() => {
                  apiDeleteNote(row.id, {code: this.searchData.code}).then(() => {
                      this.$message.success('删除成功')
                      this.getNotes()
                  })
              })
          },
          showDialog(){
              this.formData.show = true
          },
          closeDialog() {
              this.formData.show = false
          }
      }
  }
</script>
